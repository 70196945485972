<template>
<div class="py-12">
  <v-container>
    <v-card>
      <v-card-text >
         <v-form ref="form" v-model="valid">
                <v-row>
                <v-col cols="12" >
                  <p class="danger" v-if="error">{{error}}</p>
                </v-col>
                <v-col cols="12" md="6" v-for="(input , index) in inputs" :key="index">
                    <v-text-field
                    :label="input.label"
                    @keyup.enter="$refs[inputs[index+1].key].focus()"
                    :ref="input.key"
                    v-model="form[input.key]"
                    :type="input.key == 'Password' ? 'password':'text' "
                    :disabled="input.disabled"
                    :rules="typeof CreateUpdateConsultuntsValidation[input.key] != 'undefined' ? CreateUpdateConsultuntsValidation[input.key] : []"
                    :hint="input.hint ? input.hint : ''"
                    :error-messages="errors[input.key]"
                    outlined
                    ></v-text-field>
                    <p class="app-error" v-if="input.key == 'Email' && error != null" >{{error}}</p>

                </v-col>
                <v-col cols="12"  md="6">
                   <v-combobox
                    item-text="Name"
                    :items="brands"
                    v-model="brand"
                    :loading="brandsLoading"
                    outlined
                    multiple
                    return-object
                    hide-details
                    label="brand "
                    single-line
                  ></v-combobox>
                </v-col>
                 <v-col cols="12"  md="6">
                   <v-combobox
                    item-text="Name"
                    :items="daysOffs"
                    v-model="dayOffs"
                    outlined
                    multiple
                    return-object
                    hide-details
                    label="day offs"
                    single-line
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                  v-model="startTimeactive"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="start time"
                      prepend-icon="mdi-calendar-blank-outline"
                      outlined
                      readonly
                      v-model="form.OpenTime"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <!-- // Determines the type of the picker - date for date picker, month for month picker -->
                  <v-time-picker
                    locale="en-in"
                    @change="startTimeChanged"
                    color="primary"
                    outlined
                    type="24hr"
                    no-title
                  ></v-time-picker>
                </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                  v-model="endTimeactive"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="end time"
                      prepend-icon="mdi-calendar-blank-outline"
                      outlined
                      readonly
                      v-model="form.CloseTime"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <!-- // Determines the type of the picker - date for date picker, month for month picker -->
                  <v-time-picker
                    locale="en-in"
                    color="primary"
                    
                    @change="endTimeChanged"
                    type="24hr"
                    no-title
                  ></v-time-picker>
                </v-menu>
                </v-col>
                 <v-col cols="12"  md="6">
                    <v-file-input
                      truncate-length="15"
                      v-model="img"
                      label="Logo"
                      outlined
                    ></v-file-input>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn  :loading="loading" @click.prevent="submit" class="w-full" color="primary">تحديث</v-btn>
                </v-col>
                </v-row>
            </v-form>

      </v-card-text>
    </v-card>
  </v-container>
</div>
</template>
<script >
import {required , CreateUpdateConsultuntsValidation } from '@/utils/validations/validations.ts'
import {CentersUpdate , CentersFind  } from '@/repositories/center'
import {Upload ,BrandsListAll} from '@/repositories/global'

 





// import {snackBar} from '@/utils/Helpers'
export default {
    data(){
        return  {
            img:null,
             error:null,
             startTimeactive:false,
             endTimeactive:false,

             daysOffs:[
               
               {
                 Id : 1,
                 Name : 'Saturday'
               },
               {
                 Id : 2,
                 Name : 'Sunday'
               },
               {
                 Id : 3,
                 Name : 'Monday'
               },
               {
                 Id : 4,
                 Name : 'Tuesday'
               },
               {
                 Id : 5,
                 Name : 'Wednesday'
               },
               {
                 Id : 6,
                 Name : 'Thursday'
               },
                {
                 Id : 7,
                 Name : 'Friday'
               },

             ],
             dayOffs:[],
            loading:false,
            valid:true,
            brands : [],
            brand:[],
            brandsLoading : false,
            consultunt:{},
            consultuntLoading:false,
            CreateUpdateConsultuntsValidation,
            inputs:[
              {
              label : "Name",
              key:"Name"
              },
              {
              label : "Location",
              key:"Location"
              },    
           
            ],  

            errors:{},
            form:{},
            required,
                
        }
    },

    created(){
      this.getBrands();
      this.getCenter()

    },
    methods:{
      getDayOffsFromObj(){
        let str = ''
        this.dayOffs.forEach(day => {
          str += day.Id + ','

        })  
        return str.slice(0,-1)
      },
       getCenter(){
         this.centerLoading = true
         CentersFind(parseInt(this.$route.params.id)).then(res => {
           this.centerLoading = false
           this.form = res
            const offs = res.DayOffs.split(',')
            offs.forEach(day => {
              this.dayOffs.push(this.daysOffs[parseInt(day) - 1])
              console.log( this.dayOffs)
            });
           this.brand = [{
             Id : res.BrandId ,
             Name : res.BrandName ,
           }]
         })
       },
      startTimeChanged(val) {
        this.form.OpenTime = val
        this.startTimeactive = false
      },
      endTimeChanged(val) {
        console.log(val)
        this.form.CloseTime = val
        this.endTimeactive = false
      },
      submit() {
        this.$refs.form.validate()
        this.form.BrandId = 1
        this.form.DayOffs = this.getDayOffsFromObj()
          if(this.valid){
             this.loading = true
                CentersUpdate(parseInt(this.$route.params.id) ,this.form).then(d => {
                    this.error = null
                    this.loading = false
                    this.error =null
                    this.$router.push("/centers")
                    
                })
                .catch(e => {
                    this.loading = false
                    window.scrollTo({
                        top: 300,
                        left: 0,
                        behavior: 'smooth'
                    });
                
                })   
          }
       },
       getBrands(){
         this.brandsLoading = true
         BrandsListAll().then(res => {
           this.brandsLoading = false
           this.brands = res
         })
       }
    },
    watch: {
    img:{
        handler: function(val)  {
            let formData = new FormData();
            formData.append('file', val);
            Upload(formData)
            .then(d => {
                console.log(d)
                this.form.Logo = d
            })
        },
    }
  },
  
  
}
</script>
